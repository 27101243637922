<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\history.vue
  FILE_NAME : history
  AUTHOR : bykim
  DATE : 2024-03-14
  DESCRIPTION : 근무스케줄 변경이력
-->
<template>
  <div>
    <div class="page_search_box line_bottom_1px">
      <div class="inner alL">
        <DateRangeBox
          ref="dateRangeBox"
          label="배정일자"
          :start-dt="searchType.customTypes.dayStart"
          :end-dt="searchType.customTypes.dayEnd"
          :on-value-changed="onDateRangeBoxValueChanged"
        />
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
      </div>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment/moment';
  import { DxButton } from 'devextreme-vue/button';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxButton, DateRangeBox },
    props: {},
    data() {
      return {
        searchType: {
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: moment().format('YYYYMMDD'),
          },
        },
        dataGrid: {
          keyExpr: 'scheduleLogId', // 그리드 키값
          refName: 'workScheduleEditHistoryGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '근무스케줄_변경이력', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: false, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: false, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true, // 페이징 사용 유무
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: true, // 페이지 정보 표시 여부
            showNavigationButtons: true, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'none', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '구분',
              dataField: 'revTypeNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
            {
              multiHeaderNm: '근무정보',
              columns: [
                {
                  caption: '근무',
                  dataField: 'workTypeNm',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                },
                {
                  dataType: 'date',
                  format: 'yyyy-MM-dd',
                  caption: '근무일',
                  dataField: 'workDt',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: true,
                  allowFiltering: false,
                },
                {
                  caption: '부서',
                  dataField: 'deptPathNmFull',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                },
                {
                  caption: '근무자',
                  dataField: 'agent',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                  calculateCellValue: function (rowData) {
                    return rowData === null || rowData === undefined ? '' : `${rowData.agtNm} [${rowData.agtid}]`;
                  },
                },
              ],
            },
            {
              multiHeaderNm: '대직정보',
              columns: [
                {
                  caption: '근무',
                  dataField: 'replacementWorkTypeNm',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                },
                {
                  dataType: 'date',
                  format: 'yyyy-MM-dd',
                  caption: '근무일',
                  dataField: 'replacementWorkDt',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: true,
                  allowFiltering: false,
                },
                {
                  caption: '부서',
                  dataField: 'replacementDeptPathNmFull',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                },
                {
                  caption: '대직자',
                  dataField: 'replacement',
                  visible: true,
                  sortOrder: 'none',
                  alignment: 'center',
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  allowGrouping: false,
                  calculateCellValue: function (rowData) {
                    return rowData?.replacementAgtNm === null || rowData?.replacementAgtNm === undefined
                      ? ''
                      : `${rowData?.replacementAgtNm} [${rowData?.replacementAgtid}]`;
                  },
                },
              ],
            },
            {
              caption: '배정자',
              dataField: 'regId',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: function (rowData) {
                return rowData === null || rowData === undefined ? '' : `${rowData.regNm} [${rowData.regId}]`;
              },
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              caption: '배정일시',
              dataField: 'regDt',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 검색 이벤트
       * @return {Promise<*>}
       */
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'scheduleLogId',
          async load() {
            const payload = {
              actionname: 'EWM_HR_WORK_SCHEDULE_LOG_LIST',
              data: {
                sort: '-regDt',
                regDt: `${vm.$refs.dateRangeBox.getStartDate()}000000~${vm.$refs.dateRangeBox.getEndDate()}235959`,
              },
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            } else {
              vm.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
            return rtnData;
          },
        });
      },
      /**
       * 날짜 범위 박스 값 변경 이벤트
       * @param e
       */
      onDateRangeBoxValueChanged(e) {
        this.onSearch();
      },
    },
    created() {},
    mounted() {
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>
